import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useSiteStore } from '@/store/site';
import { getAuthToken, useAuthToken, useAuthActions } from '@/store/auth';
import type { AccessTokenData, UserInfo } from '@nf/types/account';
import type { ApiQueryPromise } from '@/types';
import { Maybe, identity } from '@nf/utils-common/fpw/monadic';
import { compose } from '@nf/utils-common/fpw/libs';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { getResponseJwtHeader } from '@/utils/web';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { accountKeys } from './keys';
import { useAccountError } from '@/hooks';
import { SingleWalletApiErrorCode } from '@nf/utils-common/constants';

const createApiUrl = () => {
	const { apiDomain } = useSiteStore.getState();
	return new URL('Balance/GetAccountInfo', apiDomain.ctcdDomain);
};

const dressTimezone = (url: URL) => {
	const timezoneOffset = new Date().getTimezoneOffset() / -60;
	url.searchParams.set('LocalTime', String(timezoneOffset));
	return url;
};

export const fetchAccountInfo = async (): Promise<ApiQueryPromise<UserInfo>> => {
	const authToken = getAuthToken();
	const apiUrl: URL = compose(dressTimezone, createApiUrl)();
	const response = await fetchComposer.postWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`, { cause: response });
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const fetchThirdPartyToken = async (): Promise<ApiQueryPromise<AccessTokenData>> => {
	const authToken = getAuthToken();

	const response = await fetchComposer.getWithBearer(cloudUrlParser(`${useSiteStore.getState().apiDomain.ctcdDomain}/Token/GetThirdPartyAccessToken`))(authToken);
	
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`, { cause: response });
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAccount = <TData = ApiQueryPromise<UserInfo>>(select?: (data: ApiQueryPromise<UserInfo>) => TData) => {
	const apiDomain = useSiteStore(state => state.apiDomain);
	const authToken = useAuthToken();

	return useQuery({
		queryKey: accountKeys.info(),
		queryFn: fetchAccountInfo,
		staleTime: 1000 * 60 * 5,
		cacheTime: 1000 * 60 * 10,
		refetchOnWindowFocus: false,
		enabled: Boolean(authToken && apiDomain.ctcdDomain),
		select,
	});
};

export const useAccountInfo = () => {
	const { data, isLoading, isError, error, refetch } = useAccount();

	const accountResponse = data?.data;
	const user = accountResponse?.Data;

	const { updateAuthToken } = useAuthActions();
	const { handleAccountError } = useAccountError();

	useEffect(() => {
		// Set the new JWT token
		Maybe(user?.LoginUserName)
			.chain(() => Maybe(data?.jwtToken))
			.fold(
				identity,
				(jwtToken: string) => {
					updateAuthToken(jwtToken);
				}
			);
	}, [user?.LoginUserName, data?.jwtToken]);

	useEffect(() => {
		const hasValue = (obj: object, value: string) => Object.values(obj).includes(value);
		if (hasValue(SingleWalletApiErrorCode, accountResponse?.ErrorCode.toString() || '') || (user?.ID && accountResponse?.ErrorCode !== 0)) {
			handleAccountError(String(accountResponse?.ErrorCode), accountResponse?.ErrorMsg, accountResponse?.Data?.LogId);
		}
	}, [user?.ID, accountResponse?.ErrorCode, accountResponse?.ErrorMsg]);

	if (isError) {
		console.error(error);
	}

	return { 
		user: user, 
		isLoading, 
		isError, 
		error,
		refetch
	};
};

export const useAccountStatus = () => 
	useAccount(data => data.data.Data?.ActStatus);

export const useAccountSabaCoinWallet = () =>
	useAccount(data => data.data.Data?.SabaCoinWallet);