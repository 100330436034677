import { SportSettings } from '@/types/settings';

export const sportSettings: SportSettings = {
	0: { //default
		oddsPageFilterBetTypes: [
			{ betTypes: [20] }, 
			{ betTypes: [1] },        
			{ betTypes: [3] },         
		]
	},
	1: { //Soccer
		oddsPageFilterBetTypes: [
			{ betTypes: [1] },  
			{ betTypes: [3] },   
			{ betTypes: [5] },  
			{ betTypes: [7] },  
			{ betTypes: [8] },   
			{ betTypes: [15] }, 
		]
	},	
	997: { //Saba Soccer
		oddsPageFilterBetTypes: [
			{ betTypes: [1] },  
			{ betTypes: [3] },   
			{ betTypes: [5] },  
			{ betTypes: [7] },  
			{ betTypes: [8] },   
			{ betTypes: [15] }, 
		]
	},	
	2: { //Basketball
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },  
			{ betTypes: [1] },         
			{ betTypes: [3] },          
			{ betTypes: [21] }, 
			{ betTypes: [7] },          
			{ betTypes: [8] },         
		]
	},
	993: { //Saba Basketball
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },  
			{ betTypes: [1] },         
			{ betTypes: [3] },          
			{ betTypes: [21] }, 
			{ betTypes: [7] },          
			{ betTypes: [8] },         
		]
	},
	5: { //Tennis
		oddsPageFilterBetTypes: [
			{ betTypes: [20] }, 
			{ betTypes: [153] }, 
			{ betTypes: [3] },   
		]
	},
	6: { //Volleyball
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },        
			{ betTypes: [704] },       
			{ betTypes: [705] }, 
		]
	},
	9: { //Badminton
		oddsPageFilterBetTypes: [
			{ betTypes: [20] },         
			{ betTypes: [704] },       
			{ betTypes: [705] },  
		]
	},
	43: { //E-Sports
		oddsPageFilterBetTypes: [
			{ betTypes: [9001], resourceId: '01' },
			{ betTypes: [9001], resourceId: '02' },
			{ betTypes: [9001], resourceId: '03' },
			{ betTypes: [9001], resourceId: '04' },
			{ betTypes: [9001], resourceId: '05' },
			{ betTypes: [9001], resourceId: '06' },
			{ betTypes: [9001], resourceId: '07' },
			{ betTypes: [9001], resourceId: '08' },
			{ betTypes: [9001], resourceId: '09' },
			{ betTypes: [20] },
			{ betTypes: [1] },
			{ betTypes: [3] },
		]
	},
	50: { //Cricket, todo: dennis.huang, check group runs betType with PM-Erin
		groups: {
			1: 'menu_FIXED',
			2: 'menu_FANCY',
		},
		oddsPageFilterBetTypes: [
			{ betTypes: [501], group: 1 },
			{ 
				betTypes: [9401],
				group: 1,
				limitedMarkets: ['t', 'u', 'e'],
			},
			{
				betTypes: [9607, 9608, 9609, 9610, 9611, 9612],
				isResourceNeedReplace: true,
				limitedMarkets: ['l'],
				group: 1
			},     
			{ betTypes: [9539], group: 2 }, 
		]
	},
	55: { //Finance
		oddsPageFilterBetTypes: [
			{ betTypes: [20] }, 
			{ betTypes: [1] },        
			{ betTypes: [3] },         
			{ betTypes: [2] },        
		]
	}
};

export const galaxyForbiddenSports = ['181', '182', '183', '184', '185', '186', '193', '196'];
export const marsForbiddenSports = ['181', '182', '183', '184', '185', '186', '193', '196'];
export const virtualSportIds = ['175', '178', '179', '180', '181', '182', '183', '184', '185', '186', '187', '188', '189', '190', '191', '192', '193', '194', '195', '196', '197', '198', '199', '18X']
export const numberGameIds = ['161', '164']

export const moreAsianLinesSports = new Set([1, 2]);
export const NewNumberGame= [
	{ name: 'lbl_AllNG', icon: '/Content/SiteSkin/_global/common/images/NumberGame/all_number_game', href: 'Sports/s=161' }, //enable: accountModel.get('Number')
	{ name: 'lbl_161', icon: '/Content/SiteSkin/_global/common/images/NumberGame/number_game', href: 'Sports/s=161&league=15223&gametype=numbergame' }, // enable: accountModel.get('Number')
	{ name: 'lbl_TurbgNG', icon: '/Content/SiteSkin/_global/common/images/NumberGame/turbo_number_game', href: 'Sports/s=161&league=50717&gametype=numbergame' }, // enable: accountModel.get('Number')
	{ name: 'lbl_164', icon: '/Content/SiteSkin/_global/common/images/NumberGame/happy5', href: 'Sports/s=164&league=79747&gametype=happy5' }, // enable: accountModel.get('Happy5')
	{ name: 'lbl_Soccer5', icon: '/Content/SiteSkin/_global/common/images/NumberGame/soccer5', href: 'Sports/s=164&league=79747&gametype=soccer5' }, // enable: isShowSoccer5
	{ name: 'lbl_Parlay5', icon: '/Content/SiteSkin/_global/common/images/NumberGame/parlay5', href: 'Sports/s=164&league=79747&gametype=parlay5' }, // enable: isShowParlay5
]

