import { Subject } from 'rxjs';

const loginModal$ = new Subject<boolean>();
const messageModal$ = new Subject<string>();
const newbieTutorialModal$ = new Subject<boolean>();


const openLoginModal = () => loginModal$.next(true);
const openMessageModal = (message: string) => messageModal$.next(message);
const openNewbieTutorialModal = () => newbieTutorialModal$.next(true);

const nickNameModal$ = new Subject();
const openNickNameModal = () => nickNameModal$.next(true);

export const modalService = {
	onOpenLoginModel: loginModal$.asObservable(),
	onOpenMessageModal: messageModal$.asObservable(),
	onOpenNewbieTutorialModal: newbieTutorialModal$.asObservable(),
	openLoginModal,
	openMessageModal,
	openNewbieTutorialModal,
	onOpenNickNameModal: nickNameModal$.asObservable(),
	openNickNameModal,
}
