import { useSearchParams } from 'next/navigation';
import '@nf/utils-common/fpw/fpw.extensions';
import { Maybe, Extractor, matchSome } from '@nf/utils-common/fpw/monadic';
import { Nullish } from '@nf/utils-common/fpw/monoid';
import { split, stringEmptyCheck } from '@nf/utils-common/fpw/utils';
import { convertNextSearchParamsToLowerCase } from '@nf/utils-common/url';
import { convertClientLocale, convertApiLocale } from '@nf/utils-common/site';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import type { DeepLinkSiteInfo, LanguageData } from '@nf/types/site';
import { useLocale } from 'next-intl';
import { SwitchFromEnum } from '@nf/types/site';
import { useGetSiteConfigs } from './query/use-site-configs';

type MobileDeeplinkCompound = [
	sport: number | null,
	menuType: number | null,
	market: string | null,
];

export enum TokenTypeEnum {
	Default = -1,
	Neuron = 0,
	SwitchModeWithId = 1,
	SwitchModeWithJwt = 2,
	None = 3
}

interface GetMarketType {
	Success: boolean;
	Code: string | null;
	Message: string | null;
	Data: string;
}

export interface GetJwtToken {
	Success: boolean;
	Code: string | null;
	Message: string | null;
	Data: {
		Token: string;
		UserName: string;
		SiteName: string;
		Language?: string | undefined;
		Url: string;
		Skin?: number;
	};
}

export type DeepTokenData = GetJwtToken['Data'] | null;

export interface RequestJwtTokenData {
	apiDomain: string;
	token: string;
	tokenType: TokenTypeEnum;
	language: string;
	deepLinkSiteInfo: DeepLinkSiteInfo;
	accessToken: string | undefined;
	isBefore: boolean;
	isExtend?: boolean;
	GalaxyUserServerGroup?: string;
}

interface RequestDeeplinkSportData {
	apiDomain: string | undefined;
	market: string;
	leagueId: number;
	matchId: number;
	authToken: string | undefined;
}

/* support 3 method to use token to login */
const getTokenLoginUrl = (requestData: RequestJwtTokenData) => {
	let url = '/api/ApiSiteLogin/ReGenerateToken';
	if (requestData.token !== '') {
		switch (requestData.tokenType) {
			case TokenTypeEnum.Neuron:
				url = '/api/ApiSiteLogin/Login';
				break;
			case TokenTypeEnum.SwitchModeWithId:
				url = '/api/ApiSiteLogin/OtherSkinModeLoginForDesktop'; // OtherSkinModeLoginWithUser
				break;
			case TokenTypeEnum.SwitchModeWithJwt:
				url = '/api/ApiSiteLogin/OtherSkinModeLogin';
				break;
		}
	}
	return new URL(url, requestData.apiDomain);
};

const getUseTokenLoginParameter = (requestData: RequestJwtTokenData) => {
	//ReGenerateToken
	const parseRequestData = {
		isBefore: requestData.isBefore,
		isExtend: requestData.isExtend,
		Token: requestData.token,
		Lang: convertApiLocale(requestData.language),
		GalaxyUserServerGroup: requestData.GalaxyUserServerGroup,
	};
	if (requestData.deepLinkSiteInfo) {
		const skin = requestData.deepLinkSiteInfo.skinMode;
		const otype = requestData.deepLinkSiteInfo.oddsType;
		Object.assign(parseRequestData, {
			OType: otype.toString() == '-1' ? '' : otype.toString(),
			Skin: skin.toString() == '-1' ? '' : skin.toString(),
			Deposit: requestData.deepLinkSiteInfo.depositUrl,
			...((({ oddsType, skinMode, depositUrl, ...rest }) => rest)(requestData.deepLinkSiteInfo))
		})
	}
	const options = {
		body: JSON.stringify(parseRequestData),
	};
	return options;
};

export const fetchUseTokenLogin = async (requestData: RequestJwtTokenData) => {
	const resolved = await new Promise<GetJwtToken>(async (resolve, reject) => {
		const options = getUseTokenLoginParameter(requestData);
		const response = await fetchComposer.postWithBearer(getTokenLoginUrl(requestData).href, options)(requestData.accessToken === 'unuse' ? undefined : requestData.accessToken);
		if (!response.ok) {
			reject({
				status: response.status,
				response
			});
		}
		resolve(await response.json());
	});
	return resolved;
	/*if (resolved.Success) {
		return resolved.Data ?? undefined;
	} else {
		throw new Error('Error');
	}*/
};

/* use league id or match id to get target market */
export const fetchGetMarketByLeagueOrMatch = async (requestData: RequestDeeplinkSportData) => {
	if (requestData.leagueId === 0 && requestData.matchId === 0) {
		return Promise.resolve('');
	};
	const resolved = await new Promise<GetMarketType>(async (resolve, reject) => {
		const methodName = requestData.matchId == 0 ? 'GetMarketByLeagueId' : 'GetMarketByMatchId';

		const apiUrl = new URL(`api/Market/${methodName}`, requestData.apiDomain);

		if (requestData.matchId == 0) {
			apiUrl.searchParams.set('leagueId', requestData.leagueId.toString());
			apiUrl.searchParams.set('targetMarketType', requestData.market || '');
		} else {
			apiUrl.searchParams.set('matchId', requestData.matchId.toString());
		};

		const response = await fetchComposer.getWithBearer(apiUrl.href)(requestData.authToken);

		if (!response.ok) {
			reject({
				status: response.status,
				response
			});
		}
		resolve(await response.json());
	});
	return resolved.Data ?? '';
};

const convertLanguage = (lang: string, supportLanguages: LanguageData[] | undefined) => {
	const targetLang = convertApiLocale(lang);
	let foundLanguage = supportLanguages?.find(language => (
		language.ShortCode === targetLang || language.IsoCode === targetLang
	)) || undefined;
	foundLanguage = foundLanguage || (supportLanguages && supportLanguages[0]);
	return foundLanguage;
};

export const getTokenTypeEnum = (type: string | null) => {
	switch (type) {
		case '0':
			return TokenTypeEnum.Neuron;
		case '1':
			return TokenTypeEnum.SwitchModeWithId;
		case '2':
			return TokenTypeEnum.SwitchModeWithJwt;
		case '3':
			return TokenTypeEnum.None;
		default:
			return TokenTypeEnum.Default;
	}
}

/* handle query string */
export const useGalaxyDeeplinkParams = () => {
	const originalSearchParams = useSearchParams();
	const { SiteConfigsData: siteConfigData } = useGetSiteConfigs();
	const searchParams = convertNextSearchParamsToLowerCase(originalSearchParams); // 統一使用小寫取用欄位

	const getSearchParamValue = (queryName: string) => searchParams.get(queryName);

	const queryNullishExtractor =
		Extractor(getSearchParamValue)
			.map((str: string) => str || null)
			.map(Nullish);

	const searchQuery = (word: string) => queryNullishExtractor.run(word);

	const token = searchParams.get('token') ?? '';
	const tokenType = getTokenTypeEnum(searchParams.get('tokentype'));

	const getSwitchFromEnum = (type: string | null) => {
		switch (type) {
			case '0':
				return SwitchFromEnum.Mobile;
			case '1':
				return SwitchFromEnum.Desktop;
			default:
				return SwitchFromEnum.Default;
		}
	}
	const switchFrom = getSwitchFromEnum(searchParams.get('switchfrom'));

	/* Handle URLSearchParams of deeplink URL */
	const routerLocale = useLocale();
	const deepLinkLanguage = searchParams.get('lang') || routerLocale;

	const language = convertClientLocale(deepLinkLanguage);
	const supportOddsType = !!searchParams.get('otype') ? Number(searchParams.get('otype')) : -1;

	// Get compound params in mobile: rule => sport, menu type, market
	// Example => "types=1,0,e"
	const compoundValues = searchParams.get('types');

	const [compoundSport, compoundMenuType, compoundMarket]: MobileDeeplinkCompound =
		Maybe(compoundValues)
			.map(split(','))
			.fold(
				() => [],
				(types: string[]) => types
			);

	// If types and sport, market all exist in the URL, sport, market are main choices
	const querySport = ['act', 'sportid'].foldMap(searchQuery).x as string | null;
	const sport = Number(querySport ?? compoundSport) || 1;
	const menuType = Number(searchParams.get('menutype') ?? compoundMenuType) || 0;
	const market = (((['market', 'marketid'].foldMap(searchQuery).x as string | null) ?? compoundMarket) || 'l').toLowerCase();

	// Does not support multiple leagues
	const convertLeague = (leaguekey: string | null) => {
		const leagues = Maybe(leaguekey)
			.map(split(','))
			.fold(
				() => [],
				(leagues: number[]) => leagues
			);
		return leagues[0] | 0;
	};
	const league = convertLeague((['leagueid', 'leaguekey'].foldMap(searchQuery).x as string | null)) || 0;
	const match = Number(searchParams.get('matchid') ?? '0') || 0;

	/**
	 * parse mobile lite to mars if allowed skins has 8
	 */
	const deepLinkSkin: number = stringEmptyCheck(['webskintype', 'skinmode', 'skin'].foldMap(searchQuery).x as string)
		.fold(
			() => -1,
			(skin: string) => Number(skin)
		);
	const skinMode: number = matchSome(deepLinkSkin)
		.on((skin: number) => switchFrom === SwitchFromEnum.Mobile && skin === 3 && siteConfigData?.WebSkinTypeAllow.split(',').includes('8'), () => 8)
		.otherwise((skin: number) => skin);
		
	const deepLinkSiteInfo = {
		homeUrl: (['homeurl', 'apihomeurl'].foldMap(searchQuery).x as string | null) ?? '',
		depositUrl: (['deposit', 'apidepositurl'].foldMap(searchQuery).x as string | null) ?? '',
		extendSessionUrl: (['extendsessionurl', 'apiextendsessionurl'].foldMap(searchQuery).x as string | null) ?? '',
		loginUrl: searchParams.get('loginurl') ?? '',
		signUpUrl: (['signupurl', 'singupurl'].foldMap(searchQuery).x as string | null) ?? '',
		game: (['game', 'types'].foldMap(searchQuery).x as string | null) ?? '',
		gameId: (['gameid', 'bettypeid'].foldMap(searchQuery).x as string | null) ?? '',
		oddsType: supportOddsType, //TODO: odds page need use
		skinColor: searchParams.get('skincolor') ?? '',
		skinMode: skinMode, //TODO: use galaxy deeplink to change skin, phase 1 don't do this
		forceDarkMode: (searchParams.get('forcedarkmode') ?? 'false').toLowerCase() === 'true',
		tsId: searchParams.get('tsid') ?? '',
		switchFrom: switchFrom,
		isApp: (searchParams.get('isapp') ?? 'false').toLowerCase() === 'true',
		themePreview: searchParams.get('themepreview') ?? '',
		templateId: searchParams.get('templateid') ?? '',
		dptoken: searchParams.get('dptoken') ?? '',
		switchFromUrl: searchParams.get('switchfromurl') ?? '',
		lgId: searchParams.get('lgid') ?? '',
		types: searchParams.get('types') ?? '',
		childType: searchParams.get('childtype') ?? '',
		childTab: searchParams.get('childtab') ?? '',
		clearCount: Number(searchParams.get('clearcount') ?? '0') || 0,
	};

	return {
		language,
		querySport,
		sport,
		menuType,
		market,
		league,
		match,
		deepLinkSiteInfo,
		token,
		tokenType,
		doGetMarket: match !== 0 || league !== 0,
		doUseTokenLogin: true
	};
};
