'use client';

import { useEffect } from 'react';
import { getFormData } from '@nf/utils-common/form';
import { getAuthToken, useAuthToken, useAuthActions } from '@/store/auth';
import { useQuery } from '@tanstack/react-query';
import queryKeys from '@/constants/query-keys';
import { ApiQueryBasePromise } from '@/types';
import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { getResponseJwtHeader } from '@/utils/web';
import { useSiteStore } from '@/store/site';
import { cloudUrlParser } from '@/utils/aws-domain-util';

export interface ResultDropdownItem {
	ID: number | string;
	Value?: string;
	Name?: string;
}
export interface ResultDropDownListNew {
	Item1: any;
	Item2?: any;
	Item3?: any;
	Item4?: any;
	ErrorCode?: number;
}
interface DropdownFilter {
	Selecteddate?: string,
	Selectedsport?: number
}

interface ResultOptions {
	selectDate: string,
	selectSid: number,
	selectLid: number,
	selectSeaId: number,
	selectLgId: number
}

interface OutrightOptions {
	sDate: string,
	eDate: string,
	selectSid: number,
	selectLid: number,
}

export interface ResultIndex {
	Header: {};
	LeagueDic: {};
	SelectLeague: number;
	ErrorCode?: number;
}

export interface TabletSoccerDetail {
	[key: string]: any;
}

export interface TabletEsportDetail {
	[key: string]: any;
}

interface ResultUrl {
	Data: { Url: string };
	ErrorCode: number;
	ErrorDescription: string;
}
interface ResultUrlProps {
	groupId: number;
	date: string;
}

const fetchResultDropDownListNew = async (formData: DropdownFilter): Promise<ApiQueryBasePromise<ResultDropDownListNew>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/GetResultDropDownListNew', apiDomain);
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData(formData)
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useResultDropDownListNew = (formData: DropdownFilter) => {
	const authToken = useAuthToken();
	
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_DROPDOWN_LIST_NEW, formData],
		queryFn: () => fetchResultDropDownListNew(formData),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: Boolean(authToken)
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchOutrightResultDropDownListNew = async (formData: DropdownFilter): Promise<ApiQueryBasePromise<ResultDropDownListNew>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/GetOutrightResultDropDownListNew', apiDomain);
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData(formData)
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useOutrightResultDropDownListNew = (formData: DropdownFilter) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_OUTRIGHT_DROPDOWN_LIST_NEW, formData],
		queryFn: () => fetchOutrightResultDropDownListNew(formData),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchResultIndex = async (options: ResultOptions): Promise<ApiQueryBasePromise<ResultIndex>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/ResultIndex', apiDomain);
	const GMT = 0 - new Date().getTimezoneOffset() / 60;
	apiUrl.searchParams.set('GMT', GMT.toString());

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData(options)
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useResultIndex = (options: ResultOptions, isChanged: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_INDEX, options],
		queryFn: () => fetchResultIndex(options),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: isChanged,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchOutrightIndex = async (options: OutrightOptions): Promise<ApiQueryBasePromise<ResultIndex>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/OutrightIndex', apiDomain);
	const GMT = 0 - new Date().getTimezoneOffset() / 60;
	apiUrl.searchParams.set('GMT', GMT.toString());

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData(options)
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useOutrightIndex = (options: OutrightOptions, isChanged: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_OUTRIGHT_INDEX, options],
		queryFn: () => fetchOutrightIndex(options),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: isChanged,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchTabletSoccerDetail = async (matchId: number): Promise<ApiQueryBasePromise<TabletSoccerDetail>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/TabletSoccerDetail', apiDomain);

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData({ matchId: matchId })
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useTabletSoccerDetail = (matchId: number) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_TABLET_SOCCER_DETAIL, matchId],
		queryFn: () => fetchTabletSoccerDetail(matchId),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchTabletEsportDetail = async (matchId: number): Promise<ApiQueryBasePromise<TabletEsportDetail>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('/Result/TabletEsportDetail', apiDomain);

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData({ matchId: matchId })
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useTabletEsportDetail = (matchId: number) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_TABLET_ESPORT_DETAIL, matchId],
		queryFn: () => fetchTabletEsportDetail(matchId),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

// Third Party
const fetchResultUrl = async (urlInfo: ResultUrlProps): Promise<ApiQueryBasePromise<ResultUrl>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('AuthorizationCustomer/GetResultUrl', apiDomain);
	const GMT = 0 - new Date().getTimezoneOffset() / 60;
	const [month, day, year] = urlInfo.date.split('/');
	apiUrl.searchParams.set('GroupId', urlInfo.groupId.toString());
	apiUrl.searchParams.set('homeUrl', window.location.hostname);
	apiUrl.searchParams.set('date', `${year}-${month}-${day}`);
	apiUrl.searchParams.set('GMT', GMT.toString());

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useResultUrl = (urlInfo: ResultUrlProps) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_URL, urlInfo],
		queryFn: () => fetchResultUrl(urlInfo),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchAuthorizationSportsLotteryResult = async (isDarkMode: boolean): Promise<ApiQueryBasePromise<string>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('AuthorizationCustomer/AuthorizationSportsLotteryResult', apiDomain);
	const GMT = 0 - new Date().getTimezoneOffset() / 60;
	apiUrl.searchParams.set('isDarkMode', isDarkMode.toString());
	apiUrl.searchParams.set('usertimezone', `${GMT}`);
	apiUrl.searchParams.set('isRedirect', '1');

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAuthorizationSportsLotteryResult = (isDarkMode: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_AUTHORIZATION_SPORTS_LOTTERY_RESULT, isDarkMode],
		queryFn: () => fetchAuthorizationSportsLotteryResult(isDarkMode),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchAuthorizationIndo4DResult = async (isDarkMode: boolean): Promise<ApiQueryBasePromise<string>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('AuthorizationCustomer/AuthorizationIndo4DResult', apiDomain);
	apiUrl.searchParams.set('isDarkMode', isDarkMode.toString());

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAuthorizationIndo4DResult = (isDarkMode: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_AUTHORIZATION_INDO_4D_RESULT, isDarkMode],
		queryFn: () => fetchAuthorizationIndo4DResult(isDarkMode),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchAuthorizationVirtualGameResult = async (isDarkMode: boolean): Promise<ApiQueryBasePromise<string>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('AuthorizationCustomer/AuthorizationVirtualGameResult', apiDomain);
	apiUrl.searchParams.set('isDarkMode', isDarkMode.toString());

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAuthorizationVirtualGameResult = (isDarkMode: boolean) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_AUTHORIZATION_VIRTUAL_GAME_RESULT, isDarkMode],
		queryFn: () => fetchAuthorizationVirtualGameResult(isDarkMode),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchAuthorizationBF = async (date: string): Promise<ApiQueryBasePromise<string>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('AuthorizationCustomer/AuthorizationBF', apiDomain);
	const [month, day, year] = date.split('/');
	apiUrl.searchParams.set('cust', 'BF');
	apiUrl.searchParams.set('action', 'R');
	apiUrl.searchParams.set('date', `${year}${month}${day}`);

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useAuthorizationBF = (date: string) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_AUTHORIZATION_BULL_FIGHTING_RESULT, date],
		queryFn: () => fetchAuthorizationBF(date),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

const fetchN7LottoResult = async (): Promise<ApiQueryBasePromise<string>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('Result/GetN7LottoResult', apiDomain);

	const response = await fetchComposer.getWithBearer(cloudUrlParser(apiUrl.href))(authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useN7LottoResult = () => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_N7_LOTTO_RESULT],
		queryFn: () => fetchN7LottoResult(),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};

// Soccer 5 Detail
const fetchGamesResult = async (soccer5Info: any): Promise<ApiQueryBasePromise<any>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const authToken = getAuthToken();
	const apiUrl = new URL('LinkedGames/GamesResult', apiDomain);
	const [numberlist, matchId] = soccer5Info

	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl.href), {
		body: getFormData({ numberlist: numberlist, matchid: matchId })
	})(authToken);

	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	return {
		data: await response.text(),
		jwtToken: getResponseJwtHeader(response)
	};
};

export const useGamesResult = (soccer5Info: any) => {
	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: [queryKeys.RESULT_GAMES_RESULT, soccer5Info],
		queryFn: () => fetchGamesResult(soccer5Info),
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
	});
	const { updateAuthToken } = useAuthActions();

	useEffect(() => {
		const updateJwt = () => {
			if (data?.jwtToken) {
				updateAuthToken(data.jwtToken);
			}
		};

		updateJwt();
	}, [data?.jwtToken]);

	return {
		data: data?.data,
		isLoading,
		isError,
		error,
		refetch
	};
};
