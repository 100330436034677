export interface SpecialSportSetting {
    mainGroupBetTypes: number[];
    mainOtherGroup?: (number | string)[][];
    moreGroup?: (number | string)[][];
	baseBetType?: number
}

interface SpecialSportSettings {
    [key: number] : SpecialSportSetting
}

export const specialSportSettings: SpecialSportSettings = {
	6: {
		mainGroupBetTypes: [20, 704, 705],
		mainOtherGroup: [
			['3918:01', '219:01', '220:01'],
			['3918:02', '219:02', '220:02'],
			['3918:03', '219:03', '220:03'],
			['3918:04', '219:04', '220:04'],
			['3918:05', '219:05', '220:05'],
		],
		moreGroup: [
			[704, 705],
			['3918:01', '219:01', '220:01'],
			['3918:02', '219:02', '220:02'],
			['3918:03', '219:03', '220:03'],
			['3918:04', '219:04', '220:04'],
			['3918:05', '219:05', '220:05'],
		],
		baseBetType: 501
	},
	9: {
		mainGroupBetTypes: [20, 704, 705],
		mainOtherGroup: [
			['707:01', '708:01', '709:01'],
			['707:02', '708:02', '709:02'],
			['707:03', '708:03', '709:03'],
		],
		moreGroup: [
			[704, 705],
			['707:01', '708:01', '709:01'],
			['707:02', '708:02', '709:02'],
			['707:03', '708:03', '709:03'],
		],
		baseBetType: 703
	},
	50: {
		mainGroupBetTypes: [501, 9401, 5],
		baseBetType: 501
	}
} 