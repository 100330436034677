import { LayoutSettings } from './type';

export const marsLayoutSettings: LayoutSettings = {
	1: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds'
	},
	3: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds'
	},
	7: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds'
	},
	8: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds'
	},
	14: {
		0: 'Col-3'
	},
	17: {
		0: 'Row-Title-Col-3'
	},
	18: {
		0: 'Row-Title-Col-3'
	},
	24: {
		0: 'Col-3'
	},
	127: {
		0: 'Col-3'
	},
	151: {
		0: 'Col-3'
	},
	153: {
		0: 'Row-Title-Col-3'
	},
	155: {
		0: 'Row-Title-Col-3'
	},
	171: {
		0: 'Col-2-Goal-Options'
	},
	186: {
		0: 'Col-3'
	},
	219: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	220: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	223: {
		0: 'Col-2-Choice-Options'
	},
	224: {
		0: 'Col-2-Choice-Options'
	},
	226: {
		0: 'Col-3'
	},
	227: {
		0: 'Col-3'
	},
	397: {
		0: 'Col-2-Goal-Options'
	},
	398: {
		0: 'Col-3'
	},
	401: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds',
		6: 'Col-2-Goal-Odds'
	},
	402: {
		0: 'Row-Title-Col-3',
		2: 'Col-2-Goal-Odds',
		6: 'Col-2-Goal-Odds'
	},
	403: {
		2: 'Col-2-Goal-Odds'
	},
	404: {
		2: 'Col-2-Goal-Odds'
	},
	426: {
		0: 'Col-2-Goal-Options'
	},
	451: {
		0: 'Col-3'
	},
	455: {
		0: 'Col-3'
	},
	461: {
		0: 'Row-Title-Col-3'
	},
	462: {
		0: 'Row-Title-Col-3'
	},
	463: {
		0: 'Row-Title-Col-3'
	},
	464: {
		0: 'Row-Title-Col-3'
	},
	482: {
		0: 'Row-Title-Col-3'
	},
	483: {
		0: 'Row-Title-Col-3'
	},
	484: {
		0: 'Row-Title-Col-3'
	},
	485: {
		0: 'Row-Title-Col-3'
	},
	501: {
		50: 'Col-2-Goal-Odds'
	},
	601: {
		2: 'Col-2-Goal-Options'
	},
	602: {
		2: 'Col-2-Goal-Options'
	},
	606: {
		2: 'Col-2-Goal-Odds'
	},
	607: {
		2: 'Col-2-Goal-Odds'
	},
	608: {
		2: 'Col-2-Goal-Options'
	},
	609: {
		2: 'Col-2-Goal-Odds'
	},
	610: {
		2: 'Col-2-Goal-Odds'
	},
	613: {
		2: 'Col-2-Goal-Odds'
	},
	614: {
		2: 'Col-2-Goal-Options'
	},
	615: {
		2: 'Col-2-Goal-Odds'
	},
	616: {
		2: 'Col-2-Goal-Odds'
	},
	618: {
		2: 'Col-2-Choice-Options'
	},
	619: {
		2: 'Col-2-Choice-Options'
	},
	620: {
		2: 'Col-2-Choice-Options'
	},
	621: {
		2: 'Col-2-Choice-Options'
	},
	622: {
		2: 'Col-2-Choice-Options'
	},
	623: {
		2: 'Col-2-Choice-Options'
	},
	624: {
		2: 'Col-2-Choice-Options'
	},
	625: {
		2: 'Col-2-Choice-Options'
	},
	626: {
		2: 'Col-2-Choice-Options'
	},
	627: {
		2: 'Col-2-Choice-Options'
	},
	628: {
		2: 'Col-2-Choice-Options'
	},
	629: {
		2: 'Col-2-Choice-Options'
	},
	631: {
		2: 'Col-3'
	},
	632: {
		2: 'Col-2-Choice-Options'
	},
	633: {
		2: 'Col-2-Choice-Options'
	},
	637: {
		2: 'Col-2-Goal-Odds'
	},
	638: {
		2: 'Col-2-Goal-Odds'
	},
	639: {
		2: 'Col-2-Goal-Odds'
	},
	640: {
		2: 'Col-2-Choice-Options'
	},
	641: {
		2: 'Col-2-Goal-Odds'
	},
	643: {
		2: 'Col-2-Choice-Options'
	},
	644: {
		2: 'Col-2-Choice-Options'
	},
	701: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	704: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	705: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	708: {
		0: 'Row-Title-Col-3'
	},
	709: {
		0: 'Row-Title-Col-3'
	},
	3904: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	3905: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	3907: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	3908: {
		0: 'Row-Title-Col-3',
		6: 'Col-2-Goal-Odds'
	},
	9002: {
		43: 'Col-2-Goal-Odds'
	},
	9003: {
		43: 'Col-2-Goal-Odds'
	},
	9008: {
		43: 'Col-2-Goal-Odds'
	},
	9009: {
		43: 'Col-2-Goal-Odds'
	},
	9012: {
		43: 'Col-2-Goal-Odds'
	},
	9013: {
		43: 'Col-2-Goal-Odds'
	},
	9018: {
		43: 'Col-2-Goal-Odds'
	},
	9024: {
		43: 'Col-2-Goal-Odds'
	},
	9025: {
		43: 'Col-2-Goal-Odds'
	},
	9028: {
		43: 'Col-2-Goal-Odds'
	},
	9029: {
		43: 'Col-2-Goal-Odds'
	},
	9034: {
		43: 'Col-2-Goal-Odds'
	},
	9035: {
		43: 'Col-2-Goal-Odds'
	},
	9040: {
		43: 'Col-2-Goal-Odds'
	},
	9041: {
		43: 'Col-2-Goal-Odds'
	},
	9046: {
		43: 'Col-2-Goal-Odds'
	},
	9047: {
		43: 'Col-2-Goal-Odds'
	},
	9052: {
		43: 'Col-2-Goal-Odds'
	},
	9053: {
		43: 'Col-2-Goal-Odds'
	},
	9058: {
		43: 'Col-2-Goal-Odds'
	},
	9059: {
		43: 'Col-2-Goal-Odds'
	},
	9060: {
		43: 'Col-2-Goal-Odds'
	},
	9070: {
		43: 'Col-2-Goal-Odds'
	},
	9077: {
		43: 'Col-2-Goal-Odds'
	},
	9089: {
		43: 'Col-2-Goal-Odds'
	},
	9090: {
		43: 'Col-2-Goal-Odds'
	},
	9091: {
		43: 'Col-2-Goal-Odds'
	},
	9092: {
		43: 'Col-2-Goal-Odds'
	},
	9093: {
		43: 'Col-2-Goal-Odds'
	},
	9094: {
		43: 'Col-2-Goal-Odds'
	},
	9095: {
		43: 'Col-2-Goal-Odds'
	},
	9096: {
		43: 'Col-2-Goal-Odds'
	},
	9097: {
		43: 'Col-2-Goal-Odds'
	},
	9098: {
		43: 'Col-2-Goal-Odds'
	},
	9099: {
		43: 'Col-2-Goal-Odds'
	},
	9100: {
		43: 'Col-2-Goal-Odds'
	},
	9117: {
		43: 'Col-2-Goal-Odds'
	},
	9118: {
		43: 'Col-2-Goal-Odds'
	},
	9119: {
		43: 'Col-2-Goal-Odds'
	},
	9121: {
		43: 'Col-2-Goal-Odds'
	},
	9123: {
		43: 'Col-2-Goal-Odds'
	},
	9126: {
		43: 'Col-2-Goal-Odds'
	},
	9127: {
		43: 'Col-2-Goal-Odds'
	},
	9128: {
		43: 'Col-2-Goal-Odds'
	},
	9129: {
		43: 'Col-2-Goal-Odds'
	},
	9131: {
		43: 'Col-2-Goal-Odds'
	},
	9134: {
		43: 'Col-2-Goal-Odds'
	},
	9136: {
		43: 'Col-2-Goal-Odds'
	},
	9137: {
		43: 'Col-2-Goal-Odds'
	},
	9139: {
		43: 'Col-2-Goal-Odds'
	},
	9140: {
		43: 'Col-2-Goal-Odds'
	},
	9401: {
		50: 'Col-2-Goal-Odds'
	},
	9404: {
		50: 'Col-2-Goal-Odds'
	},
	9405: {
		50: 'Col-2-Goal-Odds'
	},
	9406: {
		50: 'Col-2-Goal-Odds'
	},
	9407: {
		50: 'Col-2-Goal-Odds'
	},
	9408: {
		50: 'Col-2-Goal-Odds'
	},
	9409: {
		50: 'Col-2-Goal-Odds'
	},
	9410: {
		50: 'Col-2-Goal-Odds'
	},
	9411: {
		50: 'Col-2-Goal-Odds'
	},
	9412: {
		50: 'Col-2-Goal-Odds'
	},
	9413: {
		50: 'Col-2-Goal-Odds'
	},
	9414: {
		50: 'Col-2-Goal-Odds'
	},
	9415: {
		50: 'Col-2-Goal-Odds'
	},
	9416: {
		50: 'Col-2-Goal-Odds'
	},
	9417: {
		50: 'Col-2-Goal-Odds'
	},
	9418: {
		50: 'Col-2-Goal-Odds'
	},
	9419: {
		50: 'Col-2-Goal-Odds'
	},
	9420: {
		50: 'Col-2-Goal-Odds'
	},
	9421: {
		50: 'Col-2-Goal-Odds'
	},
	9422: {
		50: 'Col-2-Goal-Odds'
	},
	9423: {
		50: 'Col-2-Goal-Odds'
	},
	9428: {
		50: 'Col-2-Goal-Odds'
	},
	9429: {
		50: 'Col-2-Goal-Odds'
	},
	9430: {
		50: 'Col-2-Goal-Odds'
	},
	9431: {
		50: 'Col-2-Goal-Odds'
	},
	9432: {
		50: 'Col-2-Goal-Odds'
	},
	9433: {
		50: 'Col-2-Goal-Odds'
	},
	9442: {
		50: 'Col-2-Goal-Odds'
	},
	9443: {
		50: 'Col-2-Goal-Odds'
	},
	9446: {
		50: 'Col-2-Goal-Odds'
	},
	9447: {
		50: 'Col-2-Goal-Odds'
	},
	9448: {
		50: 'Col-2-Goal-Odds'
	},
	9449: {
		50: 'Col-2-Goal-Odds'
	},
	9450: {
		50: 'Col-2-Goal-Odds'
	},
	9451: {
		50: 'Col-2-Goal-Odds'
	},
	9452: {
		50: 'Col-2-Goal-Odds'
	},
	9453: {
		50: 'Col-2-Goal-Odds'
	},
	9454: {
		50: 'Col-2-Goal-Odds'
	},
	9455: {
		50: 'Col-2-Goal-Odds'
	},
	9456: {
		50: 'Col-2-Goal-Odds'
	},
	9457: {
		50: 'Col-2-Goal-Odds'
	},
	9458: {
		50: 'Col-2-Goal-Odds'
	},
	9459: {
		50: 'Col-2-Goal-Odds'
	},
	9460: {
		50: 'Col-2-Goal-Odds'
	},
	9461: {
		50: 'Col-2-Goal-Odds'
	},
	9462: {
		50: 'Col-2-Goal-Odds'
	},
	9463: {
		50: 'Col-2-Goal-Odds'
	},
	9464: {
		50: 'Col-2-Goal-Odds'
	},
	9465: {
		50: 'Col-2-Goal-Odds'
	},
	9604: {
		50: 'Col-2-Goal-Odds'
	},
	9607: {
		50: 'Col-2-Goal-Odds'
	},
	9608: {
		50: 'Col-2-Goal-Odds'
	},
	9609: {
		50: 'Col-2-Goal-Odds'
	},
	9610: {
		50: 'Col-2-Goal-Odds'
	},
	9611: {
		50: 'Col-2-Goal-Odds'
	},
	9612: {
		50: 'Col-2-Goal-Odds'
	},
	9613: {
		50: 'Col-2-Goal-Odds'
	},
	9614: {
		50: 'Col-2-Goal-Odds'
	},
	9616: {
		50: 'Col-2-Goal-Odds'
	},
	9617: {
		50: 'Col-2-Goal-Odds'
	},
	9676: {
		50: 'Col-2-Goal-Odds'
	},
	9678: {
		50: 'Col-2-Goal-Odds'
	},
	9680: {
		50: 'Col-2-Goal-Odds'
	},
	9682: {
		50: 'Col-2-Goal-Odds'
	},
	9684: {
		50: 'Col-2-Goal-Odds'
	},
	9686: {
		50: 'Col-2-Goal-Odds'
	},
	9688: {
		50: 'Col-2-Goal-Odds'
	},
	9690: {
		50: 'Col-2-Goal-Odds'
	},
	9692: {
		50: 'Col-2-Goal-Odds'
	},
	9694: {
		50: 'Col-2-Goal-Odds'
	},
	9696: {
		50: 'Col-2-Goal-Odds'
	},
	9697: {
		50: 'Col-2-Goal-Odds'
	},
	9698: {
		50: 'Col-2-Goal-Odds'
	},
	9699: {
		50: 'Col-2-Goal-Odds'
	},
	9702: {
		50: 'Col-2-Goal-Odds'
	},
	9703: {
		50: 'Col-2-Goal-Odds'
	},
	9704: {
		50: 'Col-2-Goal-Odds'
	},
	9705: {
		50: 'Col-2-Goal-Odds'
	},
	9708: {
		50: 'Col-2-Goal-Odds'
	},
	9709: {
		50: 'Col-2-Goal-Odds'
	},
	9710: {
		50: 'Col-2-Goal-Odds'
	},
	9711: {
		50: 'Col-2-Goal-Odds'
	},
	9730: {
		50: 'Col-2-Goal-Odds'
	},
	9731: {
		50: 'Col-2-Goal-Odds'
	},
	9732: {
		50: 'Col-2-Goal-Odds'
	},
	9733: {
		50: 'Col-2-Goal-Odds'
	},
	9734: {
		50: 'Col-2-Goal-Odds'
	},
	9735: {
		50: 'Col-2-Goal-Odds'
	},
	9736: {
		50: 'Col-2-Goal-Odds'
	},
	9737: {
		50: 'Col-2-Goal-Odds'
	},
	9738: {
		50: 'Col-2-Goal-Odds'
	},
	9739: {
		50: 'Col-2-Goal-Odds'
	}
}
